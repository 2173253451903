import React from 'react';
import ProviderGallery from "../../components/provider-gallery/provider-gallery.component";
import HeaderComponent from "../../components/header/header.component";
import {Route} from "react-router";

import './providers-gallery.styles.scss';

import heroImage from '../../img/providers-header.png'
import {Helmet} from "react-helmet";
const pageTitle = "Providers";
const ProvidersPage = ( {match} ) => (
    <main>
        <Helmet>
            <title>{pageTitle} | Urology Specialists of Nevada</title>
        </Helmet>
        <div className="provider-gallery-page">
            <HeaderComponent title={pageTitle} imageUrl={heroImage}/>
            <div className='row justify-content-around'>
                <div className="col-md-8 col-sm-12">
                    <p className="text-centered">
                        Our providers
                    </p>
                    <div className="divider-broken arrow-down"></div>
                </div>
            </div>
            <Route exact path={`${match.path}`} component={ProviderGallery}/>
        </div>
    </main>
)

export default ProvidersPage;
