import React from 'react';
import HeaderComponent from "../../../components/header/header.component";
import ctaImage from '../../../img/med-records-3.png'
import headerImage from '../../../img/medical-records-hero.png'
import ButtonsDirectoryComponent from "../../../components/buttons-directory/buttons-directory.component";

import MEDICAL_RECORDS_DATA from './request-medical-records.data';
import CTAComponent from "../../../components/call-to-action/cta-footer.component";
import ImageWithParagraphsComponent from "../../../components/image-with-paragraphs/image-with-paragraphs.component";
import {Helmet} from "react-helmet";

const pageTitle = "Request your medical records";

class RequestYourMedicalRecordsPage extends React.Component {

    state = MEDICAL_RECORDS_DATA;

    render()
    {
        return (
            <main>
                <Helmet>
                    <title>{pageTitle} | Urology Specialists of Nevada</title>
                </Helmet>
                <HeaderComponent imageUrl={headerImage}
                                 title={pageTitle}
                                 innerCopy="How to see the information we’ve recorded about your health."
                />

                <ButtonsDirectoryComponent buttons={this.state.buttons}/>
                {
                    this.state.imageWithParagraphs.map( ({ id, ...otherSectionProps }) => (
                        <ImageWithParagraphsComponent key={id} {...otherSectionProps} />
                    ))
                }
                <CTAComponent
                    imageUrl={ctaImage}
                    imageHeight='350'
                />
            </main>)
    }
}

export default RequestYourMedicalRecordsPage;
