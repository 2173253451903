import React from 'react';
import { withRouter } from 'react-router-dom';
import "./auxiliary.styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faTwitter, faFacebookSquare, faInstagram, faYelp} from "@fortawesome/free-brands-svg-icons"


const AuxiliaryComponent = () => (
    <div className="container-fluid top-bar">
        <div className="row">
            <div className="d-flex col-12 justify-content-end">
                <ul className="social">
                    <li>
                        <a href="https://twitter.com/USONV" target="_blank">
                            <FontAwesomeIcon icon={faTwitter} size="lg"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/USONV" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebookSquare} size="lg"/>
                        </a>
                    </li>
                </ul>
                <span class = "auxiliary-text-patient-portal"><a href = "https://pxpportal.nextgen.com/urologyspecialistsofnevada-28712/portal/#/dashboard" target="_blank">PATIENT PORTAL</a></span>
            </div>
        </div>
    </div>



)

export default withRouter(AuxiliaryComponent);
