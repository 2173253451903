import React from 'react';
import {Switch, Route} from 'react-router-dom';

import './App.css';
import './main.styles.scss'
import './main'

import AuxiliaryComponent from "./components/auxiliary/auxiliary-bar.component"
import NavigationComponent from "./components/navigation/navigation.component";
import HomePage from './pages/homepage/homepage.component';
import ProvidersPage from "./pages/providers-gallery/providers-gallery.component";
import LocationsPage from "./pages/locations/locations.component";
import MensHealthPage from "./pages/services/mens-health/mens-health.component";
import FooterComponent from "./components/footer/footer.component"
import RequestYourMedicalRecordsPage
    from "./pages/patient-resources/request-your-medical-records/request-your-medical-records.component";
import WomensHealthPage from "./pages/services/womens-health.component.jsx/womens-health.component";
import NewPatientInformationPage
    from "./pages/patient-resources/new-patient-information/new-patient-information.component";
import ProcedureConsentFormsPage
    from "./pages/patient-resources/procedure-consent-forms/procedure-consent-forms.component";
import HowToPayYourBillPage from "./pages/patient-resources/how-to-pay-your-bill/how-to-pay-your-bill.component";
import AboutUsPage from "./pages/about-us/about-us.component";
import CancerCarePage from "./pages/services/cancer-care/cancer-care.component";
import ProviderTemplatePage from "./pages/providers-single-page/providers-template.component";
import ArticlePage from "./articles/articles.component";

// import TagManager from 'react-gtm-module'
// const tagManagerArgs = {gtmId: 'GTM-WMT6G8L'}
// TagManager.initialize(tagManagerArgs)

class App extends React.Component {

    render(){
        return (
            <div>
                <AuxiliaryComponent/>
                <NavigationComponent/>
                <Switch>
                    <Route exact path='/' component={HomePage} />
                    <Route exact path='/about-us' component={AboutUsPage} />
                    <Route exact path='/providers' component={ProvidersPage} />
                    <Route exact path='/locations' component={LocationsPage} />
                    <Route exact path='/services/mens-health' component={MensHealthPage} />
                    <Route exact path='/services/womens-health' component={WomensHealthPage} />
                    <Route exact path='/services/cancer-care' component={CancerCarePage} />
                    <Route exact path='/patient-resources/request-your-medical-records' component={RequestYourMedicalRecordsPage} />
                    <Route exact path='/patient-resources/new-patient-information' component={NewPatientInformationPage} />
                    <Route exact path='/patient-resources/procedure-consent-forms' component={ProcedureConsentFormsPage} />
                    <Route exact path='/patient-resources/how-to-pay-your-bill' component={HowToPayYourBillPage} />
                    <Route path='/providers/:providerId' component={ProviderTemplatePage} />
                    <Route path='/articles/:articleId' component={ArticlePage} />
                    <Route component={HomePage} />
                </Switch>
                <FooterComponent/>
            </div>
        );
    }
}

export default App;
