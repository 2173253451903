import robertMcbeathGrid from '../../img/providers/grid/Robert-McBeath.png';
import robertMcbeath from '../../img/providers/Robert-McBeath.png';

import jasonZommickGrid from '../../img/providers/grid/Jason-Zommick.png';
import jasonZommick from '../../img/providers/Jason-Zommick.png';

import michaelFinkelsteinGrid from '../../img/providers/grid/Michael-Finkelstein.png';
import michaelFinkelstein from '../../img/providers/Michael-Finkelstein.png';

import mulugetaKassahunGrid from '../../img/providers/grid/Mulugeta-Kassahun.png';
import mulugetaKassahun from '../../img/providers/Mulugeta-Kassahun.png';

import davidLudlowGrid from '../../img/providers/grid/David-V-Ludlow.png';
import davidLudlow from '../../img/providers/David-V-Ludlow.png';

import KhuuTuanGrid from '../../img/providers/KhuuTuanGrid.png';
import KhuuTuan from '../../img/providers/KhuuTuanInterior.png';
/* Sarah Ryan */
import sarahRyanGrid from '../../img/providers/grid/Sarah-Ryan.png';
import sarahRyan from '../../img/providers/Sarah-Ryan.png';

/* Craig Hunter */
import craigHunterGrid from '../../img/providers/grid/Craig-Hunter.png';
import craigHunter from '../../img/providers/Craig-Hunter.png';

import jefferyWilsonGrid from '../../img/providers/grid/Jeffery-Wilson.png';
import jefferyWilson from '../../img/providers/Jeffery-Wilson.png';

import jasonOrienGrid from '../../img/providers/grid/Jason-Orien.png';
import jasonOrien from '../../img/providers/Jason-Orien.png';

import tuanKhuuGrid from '../../img/providers/grid/male.png';
import tuanKhuu from '../../img/providers/male.png';

/*import tinaPaloneGrid from '../../img/providers/grid/Tina-Palone.png';
import tinaPalone from '../../img/providers/Tina-Palone.png';*/

import jonathanHigginsonGrid from '../../img/providers/grid/Jonathan-Higginson.png';
import jonathanHigginson from '../../img/providers/Jonathan-Higginson.png';

/*import ernestineNgamGrid from '../../img/providers/grid/female.png';
import ernestineNgam from '../../img/providers/female.png';*/

import LanetteRickbornGrid from '../../img/providers/grid/LanetteRickborn_grid.png';
import LanetteRickborn from '../../img/providers/LanetteRickborn_MD.jpg';

import MiguelBuencaminoGrid from '../../img/providers/grid/Miguel-Buencamino-Grid.jpg';
import MiguelBuencamino from '../../img/providers/Miguel-Buencamino.jpg';

import ElizabethSmedleyGrid from '../../img/providers/grid/Elizabeth-Smedley-Grid.jpg';
import ElizabethSmedley from '../../img/providers/Elizabeth-Smedley.jpg';

import MariaCayananGrid from '../../img/providers/grid/Maria-Cayanan-grid.jpg';
import MariaCayanan from '../../img/providers/Maria-Cayanan.jpg';

import femaleGrid from '../../img/providers/grid/female.png';
import female from '../../img/providers/female.png';

import maleGrid from '../../img/providers/grid/male.png';
import male from '../../img/providers/male.png';

const PROVIDERS_DATA = {
    sections: [
        {
            imageUrl: jasonZommickGrid,
            providerName: "Jason Zommick",
            providerTitle: "MD, FACS",
            linkUrl: "jason-zommick",
            providerInteriorImage: jasonZommick,
            sex: "Male",
            providerBio: `Dr. Zommick has been with Urology Specialists of Nevada since 2001, and has been board certified since 2003. He practices in all areas of general urology, specifically new technologies for prostate health. Dr. Zommick has a special interest in minimally invasive vasectomies, and has performed more than 2,000 procedures. In his quest to provide new and effective treatment options for his patients, Dr. Zommick has also helped to initiate clinical research at USONV. He is also a single digit handicap golfer, an accomplished magician, a chef always looking for the ultimate shrimp scampi recipe, an occasional salsa dancer with his wife Amanda, and a sideline soccer coach for his young son, Aiden.`,
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Brown University, School of Medicine, Providence, RI',
                    icon: 'graduation-cap'
                },
                {
                    type: 'Residency',
                    name: 'University of Southern California, School of Medicine, Los Angeles, CA',
                    icon: 'user-md '
                }
            ],
            organizations: [
                'American Urological Association',
                'American Association of Clinical Urologists',
                'Nevada State Medical Association',
                'Clark County Medical Society',
                'Las Vegas Urological Society',
                'Fellow, American College of Surgeons'
            ],
            id: 2
        },
        {
            imageUrl: michaelFinkelsteinGrid,
            providerName: "Michael Finkelstein",
            providerTitle: "MD",
            linkUrl: "michael-finkelstein",
            providerInteriorImage: michaelFinkelstein,
            sex: "Male",
            providerBio: 'Dr. Finkelstein was born on a naval base in Subic Bay, Philippines, and grew up in the suburbs of New York City. After finishing his residency, Dr. Finkelstein practiced in Steamboat Springs, Colorado, for two years. He joined Urology Specialists of Nevada in 2004, where he practices all aspects of general urology. He adores his family and is an avid skier, fly fisherman, and all-around outdoorsman',
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Hahnemann University, School of Medicine, Philadelphia, PA',
                    icon: 'graduation-cap'
                },
                {
                    type: 'Residency',
                    name: 'New York Medical College, Valhalla, NY',
                    icon: 'user-md '
                }
            ],
            organizations: [
                'American Urological Association',
                'American Association of Clinical Urologists',
                'Sexual Medical Society of North America',
                'Las Vegas Urological Society'
            ],
            id: 3
        },
        {
            imageUrl: mulugetaKassahunGrid,
            providerName: "Mulugeta Kassahun",
            providerTitle: "MD, FACS",
            linkUrl: "mulugeta-kassahun",
            providerInteriorImage: mulugetaKassahun,
            providerBio: 'Dr. Kassahun is a native of Ethiopia and is fluent in Ambaric (Ethiopia’s official language). He was a clinical pharmacist in Denver before deciding to pursue a medical degree, and to specialize in urology. He has been with Urology Specialists of Nevada since 2004 and he practices all forms of general urology with special interests in in treating prostate cancer and kidney cancer with robotics, kidney cancer treatment with laparoscopy, and robotic surgery for female pelvic reconstructive surgery for cystocele and rectocele. He is a retired Lieutenant Colonel from the U.S. Army Reserves, where he served two tours of duty in support of “Operation Iraqi Freedom.” His last tour was in Balad, Iraq. He enjoys jogging and is an avid reader.',
            sex: "Male",
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Wisconsin Medical School, Madison, WI',
                    icon: 'graduation-cap'
                },
                {
                    type: 'Residency',
                    name: 'University of Rochester, Rochester, NY',
                    icon: 'user-md '
                }
            ],
            organizations: [
                'American Urological Association',
                'Fellow, American College of Surgeons',
                'Las Vegas Urological Society'
            ],
            id: 4
        },
        {
            imageUrl: jasonOrienGrid,
            providerName: "Jason Orien",
            providerTitle: "MD",
            linkUrl: "jason-orien",
            providerInteriorImage: jasonOrien,
            sex: "Male",
            providerBio: `Jason Orien, MD is a board-certified urologist specializing in the treatment of a range of urological conditions including prostate and kidney cancer, prostate enlargement, kidney stones, incontinence and prolapse. He performs minimally invasive vasectomies and is an experienced robotic and laparoscopic surgeon. He works diligently to provide quality care for his patients by utilizing the latest innovations in medicine and technology.
               <br><br>Dr. Orien is an avid skier, mountain biker, and fisherman. He was born and raised in Anchorage, Alaska and enjoys spending time with his wife and kids in the outdoors.`,
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Iowa Carver College of Medicine, Iowa City, IA',
                    icon: 'graduation-cap'
                },
                {
                    type: 'Residency',
                    name: 'Ohio State University Wexner Medical Center Department of Urology, Columbus, OH',
                    icon: 'user-md'
                }
            ],
            organizations: [
            ],
            id: 9
        },
        {
            imageUrl: KhuuTuanGrid,
            providerName: "Tuan Khuu",
            providerTitle: "PA-C",
            linkUrl: "tuan-khuu",
            providerInteriorImage: KhuuTuan,
            sex: "Male",
            providerBio: 'Tuan a native of Tucson, Arizona where he received his Bachelor’s degree in Health Sciences and Biochemistry and graduated Cum Laude from the University of Arizona in 2006. He then went on to earn his Master’s of Science in Physician Assistant Studies at Touro University in Henderson, Nevada in 2008. Tuan is a Certified Physician Assistant (PA-C) by The National Commission on Certification of Physician Assistants (NCCPA) and is a fellow member of the American Academy of Physician Assistants. His professional interests include minimally invasive surgery and genetic testing. Tuan is passionate about his family and enjoys raising his two daughters with his wife. They enjoy cooking, dining and maintaining a healthy and active lifestyle.',
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Touro University Nevada, Henderson, NV<br>University of Arizona, Tucson, AZ',
                    icon: 'graduation-cap'
                }
            ],
            organizations: [
            ],
            id: 10
        },
        /*{
            imageUrl: tinaPaloneGrid,
            providerName: "Tina Palone",
            providerTitle: "MSN, ARPN, FNP-C",
            linkUrl: "tina-palone",
            providerInteriorImage: tinaPalone,
            providerBio: "Tina recently joined the Urology Specialists of Nevada team as a certified family nurse practitioner. She has over 23 years of experience in health care, most recently as at a urology practice in Arizona. Tina has worked in hospital settings, clinics as a flight nurse and is a Captain in the Nurse Corps for the US Air Force Reserve.<br><br>With a diverse background in health care, Tina understands patient care from diagnosis to treatment and management for a variety of conditions. She brings a strong work ethic and a willingness to learn in her practice of medicine.",
            sex: "Female",
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Northern Arizona University, Flagstaff, AZ',
                    icon: 'graduation-cap'
                }
            ],
            organizations: [
                'American Nursing Association'
            ],
            id: 11
        },*/
        {
            imageUrl: jonathanHigginsonGrid,
            providerName: "Jonathan Higginson",
            providerTitle: "MSN, FNP-C",
            linkUrl: "jonathan-higginson",
            providerInteriorImage: jonathanHigginson,
            sex: "Male",
            providerBio: `Johnathan joins the team as an experienced family nurse practitioner from the Las Vegas area. He has worked in the emergency room as well as post anesthesia for a surgery center and as a clinical supervisor for the United Health Service.<br><br>
                Jonathan’s leadership and competence in nursing is a valuable asset to the Urology Specialists of Nevada team. As a skilled and compassionate nurse, he helps patients have a positive experience and ensures they receive the quality care they deserve.`,
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'South University, Savannah, GA',
                    icon: 'graduation-cap'
                }
            ],
            organizations: [
            ],
            id: 12
        },
        /*{
            imageUrl: ernestineNgamGrid,
            providerName: "Ernestine Ngam",
            providerTitle: "MSN, FNO-C, BSN, RN",
            linkUrl: "ernestine-ngam",
            providerInteriorImage: ernestineNgam,
            sex: "Female",
            languages: ["English"],
            providerBio: 'Ernestine is a patient-focused nurse practitioner with over 9 years of experience in health care. She has worked in private care, clinics and hospitals. She is dedicated to using her nursing education, training and interpersonal skills to ensure the highest standards of care for her patients.<br><br>She places a special importance on providing culturally competent care for a wide variety of patients with diverse needs in various clinical settings.',
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'South University, FL',
                    icon: 'graduation-cap'
                }
            ],
            organizations: [
            ],
            id: 13
        },*/
        /*{
            imageUrl: LanetteRickbornGrid,
            providerName: "Lanette Rickborn",
            providerTitle: "MD",
            linkUrl: "lanette-rickborn",
            providerInteriorImage: LanetteRickborn,
            sex: "Female",
            languages: ["English"],
            providerBio: 'Lanette Rickborn, MD joined the team in 2021. She graduated from Colorado State University with honors and then attended Ohio State University for medical school. She completed her urology residency at UT Health San Antonio. She enjoys helping patients with various urology concerns including kidney stones, prostate cancer, prostate enlargement, incontinence and erectile dysfunction. <br><br>Dr. Rickborn enjoys rock climbing at the gym and is looking forward to exploring more of Nevada’s great outdoors. She also enjoys reading, cooking and collecting stamps.',
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Ohio State University College of Medicine in Columbus, OH',
                    icon: 'graduation-cap'
                },
                {
                    type: 'Residency',
                    name: 'UT Health San Antonio in San Antonio, TX',
                    icon: 'user-md'
                }
            ],
            organizations: [
                'America Urological Association ',
                'Landacre Honor Society Interest Group, OSU College of Medicine ',
                'American Medical Association '
            ],
            id: 14
        },*/
        {
            imageUrl: ElizabethSmedleyGrid,
            providerName: "Elizabeth Smedley",
            providerTitle: "NP",
            linkUrl: "elizabeth-smedley",
            providerInteriorImage: ElizabethSmedley,
            sex: "Female",
            languages: ["English"],
            providerBio: 'Elizabeth is a certified nurse practitioner with over six years of experience. She has experience in many different specialties. Elizabeth loves getting to be part of her patient\'s lives. She takes the time to get to know her patients\' needs. Then she treats all aspects of their health for the best possible outcomes.',
            qualifications: [
                {
                    type: 'Education',
                    name: 'Walden University, Minneapolis, MN',
                    icon: 'graduation-cap'
                },
            ],
            organizations: [
            ],
            id: 15
        },
{
            imageUrl: MiguelBuencaminoGrid ,
            providerName: "Miguel Buencamino",
            providerTitle: "MSN, APRN, FNP-C",
            linkUrl: "miguel-buencamino",
            providerInteriorImage: MiguelBuencamino,
            sex: "Male",
            languages: ["English","Tagalog"],
            providerBio: 'Miguel is a board certified nurse practitioner. He has been in nursing for over a decade. Miguel loves caring for all types of patients. He is very detail-oriented and pays close attention to his patients\' needs. Miguel believes in working together with his patients to create the best plan of care.',
            qualifications: [
                {
                    type: 'Education',
                    name: 'Maryville University, St. Louis, MO',
                    icon: 'graduation-cap'
                },
            ],
            organizations: [
            ],
            id: 16
        },
        {
            imageUrl: MariaCayananGrid ,
            providerName: "Maria Eleanor Janus Cayanan",
            providerTitle: "MSN, FNP-C",
            linkUrl: "maria-cayanan",
            providerInteriorImage: MariaCayanan,
            sex: "Female",
            languages: ["English"],
            providerBio: 'Maria recently joined our team. She\'s an experienced nurse practitioner. She has worked in both hospital and clinic settings. Maria focuses on helping her patients live better lives. She understands how to treat many urologic conditions. She provides a high level of care to each of her patients.',
            qualifications: [
                {
                    type: 'Education',
                    name: 'Purdue University Global, West Lafayette, Indiana',
                    icon: 'graduation-cap'
                },
            ],
            organizations: [
                'American Nurses Association ',
            ],
            id: 17
        },


    ]
}

export default PROVIDERS_DATA;

