import React from 'react';
//import {render} from "@testing-library/react";
import DocumentGalleryItemComponent from './document-gallery-item.component';
import './document-gallery.styles.scss';
import PROCEDURE_CONSENT_FORMS_DATA from '../../pages/patient-resources/procedure-consent-forms/procedure-consent-forms.data'

class DocumentGalleryComponent extends React.Component {
    state = PROCEDURE_CONSENT_FORMS_DATA;
    render() {
        return (
            <div className="container gallery-container">
                <div className="row">
                    {
                        this.state.sections.map( ({ id, ...sectionProps }) => (
                            <DocumentGalleryItemComponent key={id} {...sectionProps} />
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default DocumentGalleryComponent;
