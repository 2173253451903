import linkUrl1 from '../../../img/documents/PD2576_16389-USONV-New-Patient-Packet_081519_V5d_test.pdf';
import linkUrl2 from '../../../img/documents/ProstateUltrasoundConsent-bx.pdf';
import linkUrl3 from '../../../img/documents/CystoConsent.pdf';
import linkUrl4 from '../../../img/documents/VasectomyConsent.pdf';
import linkUrl5 from '../../../img/documents/Notice_of_Privacy_Practices.pdf';
import linkUrl6 from '../../../img/documents/USONV_Medical_Release_Form.pdf';
import linkUrl7 from '../../../img/documents/PD2719_USONV_Obtain_PHI_Form_with_sign_here_0421.pdf';
// import linkUrl8 from '../../../img/documents/19936 USONV- Medical Release Form.pdf'

const PROCEDURE_CONSENT_FORMS_DATA = {
    sections: [
        {
            title: 'Adult patient packet',
            buttonText: 'Download packet',
            linkUrl: linkUrl1,
            id: 1
        },
        {
            title: 'Prostate ultrasound / biopsy procedure consent form',
            buttonText: 'Download form',
            linkUrl: linkUrl2,
            id: 2
        },
        {
            title: 'Cystoscopy procedure consent form',
            buttonText: 'Download form',
            linkUrl: linkUrl3,
            id: 3
        },
        {
            title: 'Vasectomy procedure consent form',
            buttonText: 'Download packet',
            linkUrl: linkUrl4,
            id: 4
        },
        {
            title: 'Notice of privacy practices',
            buttonText: 'Download form',
            linkUrl: linkUrl5,
            id: 5
        },
        {
            title: 'Authorization to release health records to patient',
            buttonText: 'Download form',
            linkUrl: linkUrl6,
            id: 6
        },
        {
            title: 'Authorization to release health records to USONV',
            buttonText: 'Download packet',
            linkUrl: linkUrl7,
            id: 7
        }
    ]
}

export default PROCEDURE_CONSENT_FORMS_DATA;
